import styled from "styled-components";

export default styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  display: flex;
  justify-content: center;
  align-content: center;
`;

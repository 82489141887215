import React from "react";
import styled from "styled-components";
import { Icon } from "react-icons-kit";
import { sun } from "react-icons-kit/iconic/sun";
import { moon } from "react-icons-kit/iconic/moon";

import Spacer from "@components/operator-lookup/Spacer";
import { ThemeContext } from "@components/ThemeContext";
import UnstyledButton from "@components/UnstyledButton";

const DarkToggle = () => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  const size = 16;

  if (!colorMode) {
    return <Spacer size={size} />;
  } else {
    return (
      <IconLink
        style={{ display: "inline-block" }}
        onClick={() => setColorMode(colorMode === "dark" ? "light" : "dark")}
      >
        <Spacer size={20}>
          <Icon
            style={{ display: "flex", alignItems: "center" }}
            icon={colorMode === "dark" ? sun : moon}
            size={size}
          />
        </Spacer>
      </IconLink>
    );
  }
};

const IconLink = styled(UnstyledButton)`
  border-bottom: none !important;
  color: var(--main-color);
  cursor: pointer;
  vertical-align: middle;

  display: flex;
  justify-content: center;

  & :hover {
    color: var(--color-primary);
  }
`;

export default DarkToggle;

import React from "react";

import "./footer.css";

const Footer = () => (
  <footer className="footer">
    <hr className="subtle" />
    <div className="copyright">
      © 2020-{new Date().getFullYear()} <span className="footer-dot">•</span>{" "}
      Craig Ferguson
    </div>
  </footer>
);

export default Footer;

import React from "react";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const siteQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

const Seo = ({ title, description, card }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(siteQuery);
  const { siteUrl } = site.siteMetadata;

  card =
    card !== undefined
      ? `${siteUrl}${String(pathname).replace(/\/$/, "")}/${card}`
      : null;

  const renderedTitle =
    !title || title === "CraigFe" ? "CraigFe" : `${title} | CraigFe`;

  return (
    <Helmet>
      <title>{renderedTitle}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={card} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@_craigfe" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={card} />
    </Helmet>
  );
};

export default Seo;

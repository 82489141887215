import styled from "styled-components";

const UnstyledButton = styled.button`
  display: block;
  margin: 0px;
  padding: 0px;
  border: medium none;
  background: transparent none repeat scroll 0% 0%;
  cursor: pointer;
  text-align: left;
  font: inherit;

  &:focus {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

export default UnstyledButton;

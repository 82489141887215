import styled from "styled-components";

const MaxWidthWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${p => p.width || "650px"};

  width: calc(100% - 64px);
  padding-left: 32px;
  padding-right: 32px;

  @media ${p => p.theme.breakpoints.smAndSmaller} {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default MaxWidthWrapper;

import React from "react";

import { Link } from "gatsby";
import styled from "styled-components";

import IconBar from "@components/IconBar";
import MaxWidthWrapper from "@components/MaxWidthWrapper";

const Nav = ({ subpath }) => (
  <>
    <MaxWidthWrapper style={{ height: "4px" }} />
    <NavWrapper>
      <NavItems>
        <NavLeft>
          <Root to="/" tabIndex={-1}>
            CRAIGFE
          </Root>
          {subpath && (
            <>
              <Separator />
              <Subpath to={"/" + subpath.text}>{subpath.text}</Subpath>
            </>
          )}
        </NavLeft>
        <IconBar style={{ padding: "2px 0" }} />
      </NavItems>
    </NavWrapper>
  </>
);

const Separator = () => (
  <span
    style={{
      fontWeight: "var(--font-weight-bold)",
      verticalAlign: "middle",
      marginLeft: "4px",
      marginRight: "4px"
    }}
  >
    /
  </span>
);

const NavWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 3;

  background: var(--color-background);
  transition: background 0.25s linear;

  padding-top: 25px;
  padding-bottom: 25px;
`;

const NavItems = styled(MaxWidthWrapper)`
  display: flex;
  align-items: center;
`;

const Root = styled(Link)`
  font-size: 16;
  font-weight: var(--font-weight-bold);
  vertical-align: middle;
`;

// The `color` and `transition` rules shouldn't be necessary, but on Chrome
// 100.0.4896.127 + MacOS it's not being set properly on theme change (causing
// an inverted theme colour) -- no idea why.
const Subpath = styled(Link)`
  text-transform: uppercase;
  vertical-align: middle;
  color: var(--color-text)!important;
  transition: var(--theme-transition)!important;
`;

const NavLeft = styled.div`
  flex: 1 1 0%;
`;

export default Nav;

import React from "react";
import styled, { css } from "styled-components";

import { Icon } from "react-icons-kit";
import { rss } from "react-icons-kit/feather/rss";
import { github } from "react-icons-kit/fa/github";
import { twitter } from "react-icons-kit/fa/twitter";
import DarkToggle from "@components/DarkToggle";
import ExternalLink from "@components/ExternalLink";

const IconBar = ({ margin }) => {
  return (
    <Wrapper margin={margin}>
      <DarkToggle />
      <Separator />
      <IconLink href="https://github.com/CraigFe" target="_blank">
        <Icon
          style={{ display: "flex", alignItems: "center" }}
          icon={github}
          size={20}
        />
      </IconLink>
      <Separator />
      <IconLink href="https://twitter.com/_craigfe" target="_blank">
        <Icon
          style={{ display: "flex", alignItems: "center" }}
          icon={twitter}
          size={20}
        />
      </IconLink>
      <Separator />
      <IconLink href="/feed.xml" target="_blank">
        <Icon
          style={{ display: "flex", alignItems: "center" }}
          icon={rss}
          size={20}
        />
      </IconLink>
    </Wrapper>
  );
};

const Separator = () => (
  <span className="footer-dot" style={{ verticalAlign: "middle" }}>
    &nbsp;&nbsp;•&nbsp;&nbsp;
  </span>
);

const Wrapper = styled.div`
  text-align: center;
  height: 20px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  user-select: none;

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;

const IconLink = styled(ExternalLink)`
  border-bottom: none !important;
  color: var(--main-color);
  cursor: pointer;
  vertical-align: middle;

  & :hover {
    color: var(--color-primary);
  }
`;

const IndexIconBar = () => <IconBar margin={"-10px 0 40px 0"} />;

export default IconBar;
export { IndexIconBar };
